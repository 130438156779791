:root {
    --primer:   #103347;
    --sekunder: #fb5757;
    --btn:      #9a525b;
    --font:     #f94c51;
    --hover:    #c85e65;
    --white:    #ffffff; 
    --grey:     #485156;
    --warning:  #848d12;
    --info:     #040d92;
    --success:  #048d12;
    --error:    #840d12;
    --black:    #010304;
    --primer-filter:   invert(99%) sepia( 37%) saturate(2079%) hue-rotate(342deg) brightness( 96%) contrast(106%);
    --sekunder-filter: invert(91%) sepia( 89%) saturate( 476%) hue-rotate(335deg) brightness( 95%) contrast( 95%);
    --btn-filter:      invert(81%) sepia( 55%) saturate( 388%) hue-rotate(355deg) brightness( 99%) contrast( 91%);
    --font-filter:     invert(98%) sepia( 24%) saturate(6292%) hue-rotate(311deg) brightness(106%) contrast( 81%);
    --hover-filter:    invert(71%) sepia( 66%) saturate( 347%) hue-rotate(346deg) brightness( 99%) contrast( 90%);
    --white-filter:    invert(87%) sepia( 25%) saturate( 611%) hue-rotate(321deg) brightness(106%) contrast(102%);
    --grey-filter:     invert(67%) sepia( 24%) saturate( 264%) hue-rotate(355deg) brightness( 85%) contrast( 86%);
    --warning-filter:  invert(87%) sepia( 72%) saturate(1216%) hue-rotate(339deg) brightness(106%) contrast(107%);
    --info-filter:     invert( 8%) sepia(100%) saturate(7499%) hue-rotate(250deg) brightness( 95%) contrast(137%);
    --success-filter:  invert(32%) sepia( 22%) saturate(5956%) hue-rotate(153deg) brightness( 93%) contrast(101%);
    --error-filter:    invert( 9%) sepia( 85%) saturate(5740%) hue-rotate(359deg) brightness( 99%) contrast(107%);
    --black-filter:    invert(36%) sepia( 13%) saturate(   0%) hue-rotate(273deg) brightness( 94%) contrast( 89%);
    --extraLarge:40px;
    --large:27px;
    --meidum:18px;
    --small:12px;
}
:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
}
.bx {
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
    display: inline-block;
    text-transform: none;
    speak-as: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon16{
    width: 16px;
    height: 16px;
    background-size: cover;
}
.icon18{
    width: 18px;
    height: 18px;
    background-size: cover;
}
.icon22{
    width: 18px;
    height: 18px;
    background-size: cover;
}
.icon24{
    width: 24px;
    height: 24px;
    background-size: cover;
}
.icon32{
    width: 32px;
    height: 32px;
    background-size: cover;
}
.icon_btn{
    filter:var(--btn-filter)
}
.icon_white{
    filter:var(--white-filter)
}
.icon_grey{
    filter:var(--grey-filter)
}
.icon_warning{
    filter:var(--warning-filter)
}
.icon_info{
    filter:var(--info-filter)
}
.icon_success{
    filter:var(--success-filter)
}
.icon_error{
    filter:var(--error-filter)
}
.icon_black{
    filter:var(--black-filter)
}
.icon_twitter{
    background-image:url("../icon/twitter.svg");
}
.icon_facebook{
    background-image:url("../icon/facebook.svg");
}
.icon_instagram{
    background-image:url("../icon/instagram.svg");
}
.icon_skype{
    background-image:url("../icon/skype.svg");
}
.icon_linkedin{
    background-image:url("../icon/linkedin.svg");
}
.icon_home{
    background-image:url("../icon/home.svg");
}
.icon_about{
    background-image:url("../icon/about.svg");
}
.icon_resume{
    background-image:url("../icon/resume.svg");
}
.icon_portfolio{
    background-image:url("../icon/portfolio.svg");
}
.icon_services{
    background-image:url("../icon/services.svg");
}
.icon_contact{
    background-image:url("../icon/contact.svg");
}
.icon_search{
    background-image:url("../icon/search.svg");
}
.icon_bell{
    background-image:url("../icon/bell.svg");
}
.icon_exclamation-circle{
    background-image:url("../icon/exclamation-circle.svg");
}
.icon_x-circle{
    background-image:url("../icon/x-circle.svg");
}
.icon_check-circle{
    background-image:url("../icon/check-circle.svg");
}
.icon_info-circle{
    background-image:url("../icon/info-circle.svg");
}
.icon_chat{
    background-image:url("../icon/chat.svg");
}
.icon_person{
    background-image:url("../icon/person.svg");
}
.icon_gear{
    background-image:url("../icon/gear.svg");
}
.icon_question-circle{
    background-image:url("../icon/question-circle.svg");
}
.icon_box-arrow-right{
    background-image:url("../icon/box-arrow-right.svg");
}
.icon_three-dots{
    background-image:url("../icon/three-dots.svg");
}
.icon_cart{
    background-image:url("../icon/cart.svg");
}
.icon_indonesian-rupiah{
    background-image:url("../icon/indonesian-rupiah.svg");
}
.icon_people{
    background-image:url("../icon/people.svg");
}
#header {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    transition: all ease-in-out 0.5s;
    z-index: 9997;
    transition: all 0.5s;
    padding: 0 15px;
    background: white;
    overflow-y: auto;
    overflow-x: hidden;
}
#header::-webkit-scrollbar {
    width: 10px;
}
#header::-webkit-scrollbar-track {
    background: var(--black); 
}
#header::-webkit-scrollbar-thumb {
    background: var(--grey); 
}
#header::-webkit-scrollbar-thumb:hover {
    background: var(--hover); 
}
#header .profile img {
    margin: 15px auto;
    display: block;
    width: 120px;
}
#header .profile h1 {
    font-size: 24px;
    margin: 0;
    padding: 0;
    font-weight: 600;
    -moz-text-align-last: center;
    text-align-last: center;
    font-family: "Poppins", sans-serif;
}
#header .profile h1 a, #header .profile h1 a:hover {
    color: var(--white);
    text-decoration: none;
}
#header .profile .social-links a:hover {
    background: var(--hover);
    text-decoration: none;
}
#header .profile .social-links a {
    font-size: 18px;
    display: inline-block;
    background: var(--grey);
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}
.nav-menu {
    padding: 30px 0 0 0;
}
.nav-menu>ul>li {
    position: relative;
    white-space: nowrap;
}
.nav-menu a:hover, .nav-menu .active, .nav-menu li:hover>a {
    text-decoration: none;
    color: var(--white);
}
.nav-menu a, .nav-menu a:focus {
    display: flex;
    align-items: center;
    color: var(--grey);
    padding: 12px 15px;
    margin-bottom: 8px;
    transition: 0.3s;
    font-size: 15px;
}
.nav-menu a:hover i, .nav-menu .active i, .nav-menu li:hover>a i {
    filter: var(--hover-filter)
}
.nav-menu a i {
    font-size: 24px;
    margin-right: 8px;
    filter: var(--font-filter)
}
.nav-menu a svg {
    margin-right: 8px;
    width: 24px;
    height: 24px;
}
.nav-menu .flex-column {
    width: 100%;
}
.header {
    transition: all 0.5s;
    z-index: 997;
    box-shadow: 0px 2px 20px var(--black);
    background-color: var(--white);
    padding-left: 20px;
}
.header .search-bar {
    margin: 10px 0;
    padding: 0 20px;
}
.header .search-form {
    width: 100%;
}
.header .search-form input {
    border: 0;
    font-size: 14px;
    color: var(--black);
    border: 1px solid var(--black);
    padding: 7px 38px 7px 8px;
    border-radius: 3px;
    transition: 0.3s;
    width: 100%;
}
.header .search-form input:focus, .header .search-form input:hover {
    outline: none;
    box-shadow: 0 0 10px 0 var(--hover);
    border: 1px solid var(--hover);
}
.header .search-form button {
    border: 0;
    padding: 0;
    margin-left: -30px;
    background: none;
}
button:focus:not(:focus-visible) {
    outline: 0;
}
.header-nav>ul {
    margin: 0;
    padding: 0;
}
.header-nav ul {
    list-style: block;
}
.header-nav .nav-icon {
    font-size: 22px;
    color: var(--font);
    margin-right: 25px;
    position: relative;
}
.header-nav .badge-number {
    position: absolute;
    inset: -2px -5px auto auto;
    font-weight: normal;
    font-size: 12px;
    padding: 3px 6px;
}
.dropdown-menu .dropdown-header, .dropdown-menu .dropdown-footer {
    text-align: center;
    font-size: 15px;
    padding: 10px 25px;
}
.dropdown-menu .dropdown-divider {
    color: var(--font);
    margin: 0;
}
.dropdown-menu-arrow::before {
    content: "";
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -6px;
    transform: rotate(45deg);
    border-top: 1px solid #eaedf1;
    border-left: 1px solid #eaedf1;
}
.dropdown-menu-arrow.notifications::before{
    left: 28px;
}
.dropdown-menu-arrow.messages::before{
    left: 44px;
}
.dropdown-menu-arrow.profile::before{
    right: 23px;
}
.info-card .filter .dropdown-menu-arrow::before{
    left: 3px;
}
.header-nav ul {
    list-style: none;
}
.header-nav .notifications{
    inset: 8px -232px auto auto !important;
}
.header-nav .notifications.show{
    position: absolute; 
    margin: 0px; 
    transform: translate(-25px, 35px);
}
.header-nav .notifications .notification-item {
    display: flex;
    align-items: center;
    padding: 15px;
    transition: 0.3s;
}
.header-nav .notifications .notification-item i {
    margin: 0 20px 0 10px;
    padding: 10px;
}
.header-nav .notifications .notification-item:hover {
    background-color: var(--white);
}
.dropdown-menu {
    border-radius: 4px;
    padding: 10px 0;
    -webkit-animation-name: dropdown-animate;
    animation-name: dropdown-animate;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    border: 0;
    box-shadow: 0 5px 30px 0 var(--black);
}
.header-nav .notifications .notification-item h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}
.header-nav .notifications .notification-item p {
    font-size: 13px;
    margin-bottom: 3px;
    color: var(--grey);
}
.dropdown-menu .dropdown-footer a {
    color: var(--grey);
    text-decoration: underline;
}
.header-nav .messages {
    inset: 8px -162px auto auto !important;
}
.header-nav .messages.show{
    position: absolute; 
    inset: 0px 0px auto auto; 
    margin: 0px; 
    transform: translate(-25px, 35px);
}
.header-nav .messages .message-item:hover {
    background-color: var(--white);
}
.header-nav .messages .message-item {
    padding: 15px 10px;
    transition: 0.3s;
}
.header-nav .messages .message-item a {
    display: flex;
    color: var(--font);
    text-decoration: none;
}
.header-nav .messages .message-item img {
    margin: 0 20px 0 10px;
    max-height: 40px;
}
.header-nav .messages .message-item h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    color: var(--grey);
}
.header-nav .messages .message-item p {
    font-size: 13px;
    margin-bottom: 3px;
    color: var(--grey);
}
.header-nav .nav-profile {
    color: var(--font);
}
.header-nav .nav-profile img {
    max-height: 36px;
}
.header-nav .nav-profile span {
    font-size: 14px;
    font-weight: 600;
}
.header-nav .profile {
    min-width: 240px;
    padding-bottom: 0;
    top: 8px !important;
    inset: 35px -9px auto auto !important;
}
.header-nav .profile .dropdown-header h6 {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 600;
    color: var(--grey);
}
.header-nav .profile .dropdown-header span {
    font-size: 14px;
}
.header-nav .profile .dropdown-item {
    font-size: 14px;
    padding: 10px 15px;
    transition: 0.3s;
}
.header-nav .profile .dropdown-item:hover {
    background-color: var(--white);
}
.header-nav .profile .dropdown-item i {
    margin-right: 10px;
    font-size: 18px;
    line-height: 0;
}
.header-nav.ms-auto{
    margin-bottom: 13px;
}


#hero {
    width: 100%;
    height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../img/no-image.jpg) top center;
    background-size: cover;
}
#hero .hero-container {
    position: relative;
    z-index: 2;
    min-width: 300px;
}
#hero h1 {
    margin: 0 0 10px 0;
    font-size: 64px;
    font-weight: 700;
    line-height: 56px;
    color: var(--white);
}
#hero p {
    color: var(--white);
    margin-bottom: 50px;
    font-size: 26px;
    font-family: "Poppins", sans-serif;
}
#hero p span {
    color: var(--white);
    padding-bottom: 4px;
    letter-spacing: 1px;
    border-bottom: 3px solid var(--sekunder);
}
#main {
    margin-top: 60px;
    padding: 20px 30px;
    transition: all 0.3s;
    margin-left: 300px;
}
.pagetitle {
    margin-bottom: 10px;
}
.pagetitle h1 {
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 600;
    color: var(--font);
}
.breadcrumb {
    font-size: 14px;
    font-family: "Nunito", sans-serif;
    color: var(--grey);
    font-weight: 600;
}
.breadcrumb a {
    color: var(--grey);
    transition: 0.3s;
}
.breadcrumb .active {
    color: var(--btn);
    font-weight: 600;
}
.dashboard .info-card {
    padding-bottom: 10px;
}
.card {
    margin-bottom: 30px;
    border-radius: 5px;
    width: 100%;
}
.main .card {
    box-shadow: 0px 0 30px var(--grey);
}
.dashboard .filter {
    position: absolute;
    right: 0px;
    top: 15px;
}
.dashboard .filter .icon:hover i, .dashboard .filter .icon:focus i {
    filter:var(--hover-filter)
}
.dashboard .filter .icon {
    color: var(--font);
    filter:var(--font-filter);
    padding-right: 20px;
    padding-bottom: 5px;
    transition: 0.3s;
    font-size: 16px;
}
.dashboard .filter .dropdown-menu{
    left: -2px;
}
.dashboard .filter .dropdown-header {
    padding: 8px 15px;
}
.dashboard .filter .dropdown-header h6 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: var(--font);
    margin-bottom: 0;
    padding: 0;
}
.dashboard .info-card h6 {
    font-size: 28px;
    color: var(--black);
    font-weight: 700;
    margin: 0;
    padding: 0;
}
.dashboard .filter .dropdown-item {
    padding: 8px 15px;
}
.dropdown-menu .dropdown-item {
    font-size: 14px;
    padding: 10px 15px;
    transition: 0.3s;
}
.dashboard .filter .dropdown-item {
    padding: 8px 15px;
}
.dropdown-menu .dropdown-item:hover {
    background-color: var(--white);
}
.dropdown-menu .dropdown-item {
    font-size: 14px;
    padding: 10px 15px;
    transition: 0.3s;
}
.card-body {
    padding: 0 20px 20px 20px;
}
.card-title {
    padding: 20px 0 15px 0;
    font-size: 18px;
    font-weight: 500;
    color: var(--black);
    font-family: "Poppins", sans-serif;
}
.card-title span {
    color: var(--grey);
    font-size: 14px;
    font-weight: 400;
}

.dashboard .card-icon {
    filter: var(--btn-filter);
    background: var(--white);
}
.dashboard .card-icon {
    font-size: 32px;
    line-height: 0;
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    flex-grow: 0;
}
.sidebar li .submenu{ 
	list-style: none; 
	margin: 0; 
	padding: 0; 
	padding-left: 1rem; 
	padding-right: 1rem;
}
