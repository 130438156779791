.whatsapp-icon {
    position: fixed;
    bottom: 70px;
    right: 20px;
    z-index: 1000;
    background-color: #25D366;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s;
}

.whatsapp-icon:hover {
    transform: scale(1.1);
}