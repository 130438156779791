:root {
    --black-e6: #010304e6;
    --black-8c: #0103048c;
    --black-1a: #0103041a;
    --black-1f: #0103041f;
    --black-01: #01030401;
    --black-4d: #0103044d;
    --info-26: #040d9226;
    --white-1a: #ffffff1a;
    --white-33: #ffffff33;
    --primer-08: #10334708;
    --primer-80: #10334780;
    --primer-26: #10334726;
    --primer-20: #10334720;
}

#hero .hero-container {
    min-width: 455px;
    min-height: 155px;
    transition: 1s;
    background-color: var(--primer-80);
    padding: 10px;
    border-radius: 10px;
}

#hero {
    justify-content: center;
}

.lazy-load:before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    border: 2px solid red;
    border-color: transparent red transparent red;
    border-radius: 50%;
    animation: loader 1s linear infinite;
}
@keyframes loader {
    0% {
        transform: translate(-50%,-50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%,-50%) rotate(360deg);
    }
}

#hero.other-page {
    padding: 0;
    justify-content: end;
}

#hero.other-page>.svg-border-rounded {
    width: 100vw;
    opacity: 1;
}

#hero>.svg-border-rounded {
    opacity: 0;
    transition: 1s;
}

#main {
    margin-left: 0px;
}

#hero .countdown {
    margin-top: 25px;
    transition: 1s;
}

#hero .countdown div {
    text-align: center;
    border-top: 2px solid var(--black);
    background-color: var(--white-1a);
    margin: 10px 10px;
    width: 100px;
    padding: 15px 0;
    transition: 1s;
}

#hero .countdown div h3 {
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 15px;
    transition: 1s;
}

#hero .countdown div h4 {
    font-size: 16px;
    font-weight: 600;
    transition: 1s;
}

h3, h4 {
    color: var(--white);
    font-family: "Raleway", sans-serif;
}

#hero h1 {
    text-align: center;
    transition: 1s;
    font-size: 26px;
    margin-bottom: 50px;
    font-weight: var(--bs-body-font-weight);
}

#hero h1::after {
    right: -30px;
}
#hero h1::before {
    left: -30px;
}

#hero h1::after, #hero h1::before {
    content: "";
    position: relative;
    top: -5px;
    border-bottom: 2px solid var(--white);
    width: 50px;
    z-index: 9;
    display: inline-block;
    transition: 0.3s ease-out;
}

#hero p {
    transition: 1s;
    font-size: 67px;
    margin-bottom: 10px;
    font-weight: 700;
    text-align: center;
}

#hero .progress-container {
    width: 300px;
}

#hero .progress {
    width: 100%;
}

.navbar-marketing {
    transition: background-color .15s ease-in-out;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
}

.navbar-marketing.fixed-top {
    max-height: 100vh;
    overflow-y: auto;
}

.navbar-marketing .navbar-brand {
    font-size: 1.2rem;
    font-weight: 700;
    width: 116px;
    margin-right: -0.227rem;
}

.navbar-marketing .navbar-toggler {
    padding: .5rem;
    border: 0;
}

.navbar-marketing .navbar-nav {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.navbar-light .navbar-brand {
    background: none;
    border: none;
    color: var(--black-e6);
}

.navbar-light .navbar-toggler {
    color: var(--black-8c);
    border-color: var(--black-1a);
}

.navbar-light .navbar-nav .nav-link {
    color: var(--white);
}

.navbar-light .navbar-nav .show>.nav-link, .navbar-light .navbar-nav .nav-link:hover {
    color: var(--black-e6);
}

.nav-item > .nav-item > .nav-link:hover {
    color: var(--hover) !important;
}

.navbar-marketing .navbar-nav .nav-item .nav-link {
    font-weight: 500;
    transition: 1s;
}

.fw-500 {
    font-weight: 500 !important;
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.dropdown .dropdown-toggle {
    display: inline-flex;
    align-items: center;
}

.dropdown .dropdown-toggle .dropdown-arrow, .lang_box .dropdown-arrow{
    margin-left: .4rem;
    margin-right: 0;
    transition: transform .1s ease-in-out;
    font-size: .6em;
}

.dropdown-toggle::after {
    display: none;
}

.dropdown.show .dropdown-arrow, .lang_box.show .dropdown-arrow {
    transform: rotate(90deg);
}

.pointer {
    cursor: pointer !important;
}

.svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
}

svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
    overflow: visible;
    box-sizing: content-box;
}

svg:not(:root).svg-inline--fa {
    overflow: visible;
}

.animated--fade-in-up {
    animation-name: fadeInUp;
    animation-duration: .3s;
    animation-timing-function: cubic-bezier(.18, 1.25, .4, 1), cubic-bezier(0, 1, .4, 1);
}

.animated--fade-in-up.dropdown-menu {
    margin-top: 0;
    top: .125rem !important;
}

.navbar .dropdown-menu {
    top: calc(100% + 1rem) !important;
    font-size: .9rem;
}

.dropdown-menu-end {
    transform: translatex(-50%);
}

.btn:hover {
    background-color: var(--white-33);
    color: var(--white) !important;
}

.button-group .btn:hover {
    background-color: var(--white-33);
    color: var(--hover) !important;
}

.dropdown-menu {
    font-size: .9rem;
    border: none;
    box-shadow: 0 .15rem 1.75rem var(--info-26);
    padding: 0;
}

.overlay {
    position: relative;
}

.bg-img-cover {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../img/no-image.jpg);
}

.z-1 {
    z-index: 1 !important;
    position: relative !important;
}

.btn-sm {
    padding: .5rem .75rem;
    font-size: .75rem;
    border-radius: .25rem;
}

.btn-white {
    color: var(--black);
    background-color: var(--white);
    border-color: var(--white);
}

.dropdown-menu .dropdown-header {
    font-size: .75rem;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.navbar .dropdown-menu .dropdown-header {
    font-size: 1rem;
    color: var(--primer);
}

.navbar .dropdown-menu .dropdown-header {
    font-weight: 700;
    letter-spacing: .125em;
    text-transform: uppercase;
}

.detail-product {
    max-height: 50vh;
    overflow-y: auto;
}

#jarallax-container-3 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -100;
    clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%);
}

#jarallax-container-3 img.single-image {
    object-fit: cover;
    object-position: 50% 50%;
    max-width: none;
    position: fixed;
    top: 0px;
    overflow: hidden;
    pointer-events: none;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    will-change: transform, opacity;
}

.row::after {
    display: block;
    clear: both;
    content: "";
}

.section-header {
    position: relative;
}

.section-header .title span {
    font-size: 14px;
    color: var(--sekunder);
    letter-spacing: 2px;
    text-transform: uppercase;
    padding-left: 30px;
    position: relative;
}

.section-header span:before {
    content: "";
    border-bottom: 2px solid var(--error);
    position: absolute;
    top: 9px;
    left: 3px;
    width: 14px;
}

.section-title {
    font-size: calc(1.8em + 2vw);
    line-height: 1;
}

h2 {
    margin: 0 0 25px;
    text-transform: capitalize;
    color: var(--error);
    font-family: "Montserrat", Roboto, sans-serif;
}

#clients .container {
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
}

#clients figure {
    margin-right: 50px;
}

#clients img.single-image {
    width: 100%;
    height: 600px;
    object-fit: cover;
}

#clients p {
    margin: 0 0 2.5rem 0;
    text-align: justify;
}

.jarallax-keep-img > .jarallax-img {
    position: relative;
    display: block;
    max-width: 100%;
    height: auto;
    z-index: -100;
}

img {
    display: inline-block;
    border: 0;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.jarallax-keep-img {
    position: relative;
    z-index: 0;
}

figure {
    margin: 0 0 2.5rem;
}

.clients .swiper-slide img {
    transition: 0.3s;
    padding: 0 10px;
}

.eof-c28 {
    display: inline-block;
    width: 24px;
    height: 24px;
    fill: none;
    stroke: currentColor;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    height: auto !important;
    width: auto !important;
}

.navbar-marketing .navbar-toggler svg {
    vertical-align: middle;
    height: 1.5rem;
    width: 1.5rem;
}

.navbar>.container {
    flex-wrap: wrap;
    transition: 1s;
    background-color: var(--primer-80);
    border-radius: 0 0 20px 20px;
}

.text-lead {
    font-size: 120%;
    line-height: 1.7em;
}

.text-lead strong {
    font-size: 115%;
    font-weight: 500;
}

.visi-misi-wrap h3 {
    color: var(--white);
    background-color: var(--btn);
    padding: 1.275em 2.295em;
    font-size: 1.035em;
    margin-right: 0;
    position: relative;
    border: 1px solid transparent;
    margin-top: 12.75px;
    font-weight: bold;
    line-height: normal;
    text-transform: uppercase;
    z-index: 1;
    white-space: nowrap;
    vertical-align: middle;
    transition: all 0.3s ease-in;
}

.navbar.scrolled {
    background-color: var(--white) !important;
    border-bottom: 1px solid var(--white);
}

.navbar.scrolled .nav-link {
    color: var(--black-8c);
}

section {
    color: var(--grey);
    background-color: var(--white);
    padding: 60px 0;
    scroll-margin-top: 88px;
    overflow: clip;
    position: relative;
}

.services .section-title {
    text-align: center;
    padding-bottom: 60px;
    position: relative;
}

.section-title h2 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    text-transform: uppercase;
    position: relative;
}

.section-title h2:before {
    content: "";
    position: absolute;
    display: block;
    width: 160px;
    height: 1px;
    background: 
color-mix(in srgb, var(--grey), transparent 60%);
    left: 0;
    right: 0;
    bottom: 1px;
    margin: auto;
}

.section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 60px;
    height: 3px;
    background: var(--grey);
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.section-title p {
    margin-bottom: 0;
    font-size: 1rem;
}

.services .service-item {
    background-color: var(--white);
    box-shadow: 0px 5px 90px 0px var(--black-1a);
    padding: 50px 30px;
    transition: all ease-in-out 0.4s;
    height: 100%;
    width: 100%;
}

.services .service-item:hover {
    transform: translateY(-10px);
}

.services .service-item .icon {
    margin-bottom: 10px;
    width: 100%;
}

.services .service-item .icon a {
    color: var(--sekunder);
}

.icon svg {
    margin: 10px;
}
.img-fluid {
    height: 100%;
}

.image-link {
    height: 239px;
}

.image-link  .img-fluid {
    object-fit: cover;
    width: 100%;
}

.card.lift {
    text-decoration: none;
    color: inherit;
}

.card.post-preview{
    margin-bottom: 0px !important;
}
.lift {
    transition: transform .15s ease-in-out, box-shadow .15s ease-in-out;
}

.container .card {
    box-shadow: 0 .15rem 1.75rem var(--primer-26);
}

.card__image {
    top: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--grey);
    z-index: 1031;
}

.loading::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: -webkit-gradient(linear, left top,
            right top, from(transparent),
            color-stop(var(--white-33)),
            to(transparent));
    background: linear-gradient(90deg, transparent,
    var(--white-33), transparent);
    animation: loading 0.8s infinite;
}

.services .service-item h4 {
    font-weight: 700;
    margin: 0 0 0 15px;
    font-size: 20px;
    color: var(--grey);
    font-family: "Jost", sans-serif;
    display: inline;
}
.services .service-item h4 a {
    color: var(--grey);
    transition: ease-in-out 0.3s;
    text-decoration: none;
}

.services .service-item p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.aos-animate ol {
    overflow-y: auto;
    position: relative;
    max-height: 237px;
}

.about ul {
    list-style: none;
    padding: 0;
}

.about ul li {
    padding-bottom: 5px;
    display: flex;
    align-items: center;
}

.about ul i {
    font-size: 20px;
    padding-right: 4px;
    color: var(--grey);
}
.about .read-more {
    color: var(--grey);
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 8px 28px;
    border-radius: 5px;
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--grey);
    text-decoration: none;
    background-color: var(--white);
}

.about .read-more:hover {
    color: var(--white);
    background-color: var(--grey);
}

hr:not([size]) {
    height: 1px;
}

#footer button {
    color: inherit !important;
    text-decoration: underline;
    border: none;
}

#services .align-items-center {
    padding: 0 10px;
}

.aos-init>p {
    text-align: justify;
    color: var(--grey);
}

.aos-init>h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 20px;
    color: var(--grey);
    font-family: "Jost", sans-serif;
}

.about ul li svg {
    margin: 0 10px;
}

.about .read-more svg {
    margin-left: 10px;
}

::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-thumb {
    background: var(--grey);
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px var(--grey);
    border-radius: 10px;
    background: transparent;
}

.our-top-clientele .ng-fa-icon {
    width: 100%;
    height: 99px;
    text-align: center;
}

.py-10 {
    padding-top: 6rem !important;
    padding-bottom: 0px;
}

.mb-10 {
    margin-bottom: 6rem !important;
}

.badge-marketing {
    padding: .5em 1em;
}

.bg-primary-soft {
    background-color: var(--white) !important;
    color: var(--primer) !important;
}

.bg-dark {
    background: var(--white) !important;
}

.padding-large {
    /* padding-top: 7em; */
    padding-bottom: 7em;
}

h2.light {
    color: var(--white);
}

.btn.btn-outline-light {
    background: transparent;
    border-color: var(--white);
    color: var(--white);
    text-shadow: none;
    box-shadow: none;
}

.btn.btn-outline {
    background: transparent;
    border-color: var(--grey);
    color: var(--font);
    text-shadow: none;
    box-shadow: none;
}

button.btn {
    background-image: none;
    background: var(--info);
    text-decoration: none !important;
    display: inline-block;
    position: relative;
    border: 1px solid transparent;
    border-radius: 0;
    padding: 0.75em 1.5em;
    margin-top: 15px;
    font-size: 15px;
    font-weight: bold;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    color: var(--white);
    z-index: 1;
    cursor: pointer;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.lang_box {
    display: flex;
    float: right;
    background: transparent;
    border-radius: 5px;
    margin-left: 0 !important;
}

.lang_box button {
    padding: 0.375rem 0.75rem;
    border-radius: 0.375rem;
}

.lang_box button:hover {
    color: var(--black-e6);
}

.lang_box img {
    height: 1rem;
}

.lang_box .dropdown-arrow {
    display: inline-block;
    width: 21px;
}
.lang_box .dropdown-menu {
    top: 5.45rem !important;
    border-radius: 0.475rem;
}

.mr-2 {
    margin-right: .5rem !important;
}

.ml-2 {
    margin-left: .5rem !important;
}

.scroll-top.active {
    visibility: visible;
    opacity: 1;
    bottom: 15px;
}

.scroll-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: -15px;
    z-index: 99999;
    background-color: var(--grey);
    width: 44px;
    height: 44px;
    border-radius: 50px;
    transition: all 0.4s;
    border: 0;
}

.scroll-top svg {
    fill: var(--white);
}

.btn-primary:hover {
    color: var(--white);
    background-color: var(--hover) !important;
    border-color: var(--hover);
}

.collapse .btn {
    transition: 1s;
}

.collapse>.btn {
    text-transform: none;
    font-size: 1rem;
    margin: 0;
    padding: 0.375rem 0.75rem;
    border-radius: 0.375rem;
    display: inline-flex;
    background: var(--sekunder);
    margin-left: 0 !important;
}
.row>.content {
    width: 558px;
    height: 501px;
}
.row>.content>.img-fluid {
    object-fit: cover;
}
.ng-fa-icon .img-fluid {
    object-fit: contain;
}

.dropdown-menu .dropdown-item:hover {
    background-color: var(--grey);
}

.card-img-top {
    height: 350px;
    object-fit: cover;
    border-top-left-radius: .35rem;
    border-top-right-radius: .35rem;
}

.list-group-careers {
    margin-bottom: 3rem;
}

.list-group-careers .list-group-item {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list-group-flush>.list-group-item {
    border-width: 0 0 1px;
}

.list-group-item button {
    background: transparent;
    border: none;
}

.my-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
}

.p-5 {
    padding: 2.5rem !important;
}

.lead {
    font-size: 1.1rem;
    font-weight: 400;
    text-align: justify;
}

.faq-item h3 {
    color: var(--black);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    transition: 0.3s;
    cursor: pointer;
}

.faq-item h3 button {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    text-align: left;
}

.faq-item h3:hover {
    padding: 3px;
}

.faq-item h3:hover button {
    color: var(--grey);
}

.faq-item {
    background-color: var(--white);
    position: relative;
    padding: 10px 20px;
    margin-bottom: 0;
    box-shadow: 0px 5px 25px 0px var(--black-01);
    overflow: hidden;
}

.faq-item .faq-content {
    display: grid;
    grid-template-rows: 0fr;
    transition: 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
    height: 0;
}

.faq-active>.faq-content {
    grid-template-rows: 1fr;
    visibility: visible;
    opacity: 1;
    padding-top: 10px;
    height: auto;
}

.faq-item .faq-content p {
    margin-bottom: 0;
    overflow: hidden;
}

.faq-item .faq-item {
    box-shadow: none;
}

.px-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
}

.gx-5 {
    --bs-gutter-x: 2.5rem;
}

.col-lg-10 h4 {
    color: var(--font);
}

.navbar-brand .img-fluid {
    max-height: 68.136px;
}

.navbar-collapse {
    justify-content: flex-end;
}

.service-item .footer-brand {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: .25rem;
}

.service-item .video {
    margin-top: 15px;
}

.service-item .read-more {
    color: var(--sekunder);
    background: none;
    border: none;
}

.service-item .read-more:hover {
    color: var(--error);
}

.post-preview-featured .post-preview-featured-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover !important;
    background: linear-gradient(var(--black-4d), var(--black-4d)), url(../img/no-image.jpg) top center;
}

.post-preview .card-body {
    min-height: 350px;
}

.interior .post-content {
    width: 100%;
    padding: 40px 30px;
    margin-bottom: 60px;
    box-shadow: -7px 12px 41px -10px var(--black-1f);
    -webkit-box-shadow: -7px 12px 41px -10px var(--black-1f);
    -moz-box-shadow: -7px 12px 41px -10px var(--black-1f);
}

.interior .meta-date {
    font-size: 14px;
    padding-bottom: 20px;
}

.interior .post-title {
    font-size: 1.4em;
    line-height: 1.4;
}

h3 a, h3 button {
    font-weight: inherit;
    color: var(--black);
    text-decoration: none;
}

.post-title button {
    background: none;
    border: none;
    cursor: pointer;
}

.interior p {
    line-height: 2;
}

.card-body {
    padding: 1.35rem;
}

.card > .card-body {
    flex: 1 1 auto;
}

.py-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
}

.post-preview-featured h5.card-title, .post-preview-featured .card-title.h5 {
    font-size: 1.75rem;
}

.post-preview h5.card-title {
    margin-bottom: .5rem;
}

.post-preview .card-body > h5.card-title {
    font-size: 1.1rem;
}

.card-footer {
    padding: 1rem 1.35rem;
    background-color: var(--primer-08);
    border-top: 1px solid var(--primer-20);
}

.pagination-blog .page-item:first-child {
    margin-left: 0;
}

.pagination-blog .page-item {
    margin-right: .25rem;
}

.pagination-blog .page-item .page-link {
    border-radius: .35rem;
    border: none;
    padding: .75rem 1rem;
    font-weight: 500;
    font-size: .9rem;
}

nav > .pagination-blog .page-item {
    margin-left: .25rem;
}
.page-item.active .page-link {
    z-index: 3;
    color: var(--white);
    background-color: var(--black);
}

.page-item:not(:first-child) .page-link {
    margin-left: -1px;
}

.pagination-blog .page-item:last-child {
    margin-right: 0;
}

.page-item.disabled .page-link {
    color: var(--hover);
    pointer-events: none;
    background-color: var(--white);
}

.card-footer:last-child {
    border-radius: 0 0 .35rem .35rem;
}

.post-preview-featured p.card-text {
    font-size: 1.2rem;
    font-weight: 300;
}

.post-preview .post-preview-meta {
    display: flex;
    align-items: center;
    margin-right: .5rem;
}

.post-preview .post-preview-meta .post-preview-meta-img {
    height: 2.25rem;
    width: 2.25rem;
    border-radius: 100%;
    margin-right: .5rem;
    flex-shrink: 0;
}

.post-preview .post-preview-meta .post-preview-meta-details .post-preview-meta-details-name {
    font-size: .9rem;
    line-height: 1;
    margin-bottom: .25rem;
}

.post-preview .post-preview-meta .post-preview-meta-details .post-preview-meta-details-date {
    font-size: .75rem;
    line-height: 1;
}

.mb-5 {
    margin-bottom: 2.5rem !important;
}

.col-lg-5 .video {
    height: 100%;
    width: 100%;
}

.text-center .btn {
    display: inline-flex;
    background-color: var(--black);
    border-color: var(--black);
    line-height: 1;
    padding: .875rem 1.125rem;
    font-size: .875rem;
    border-radius: .35rem;
}

.text-center .btn:hover {
    background-color: var(--btn);
    border-color: var(--btn);
}

.text-justify {
    text-align: justify;
}

@media only screen and (max-width: 1200px) {
    .navbar-expand-lg .navbar-collapse {
        display: block!important;
        flex-basis: 100%!important;
    }
    .collapse:not(.show) {
        display: none!important;
    }
    .navbar-expand-lg .navbar-toggler {
        display: block;
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: column!important;
    }
    .navbar-marketing.bg-transparent.navbar-light {
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
    }
    .navbar-marketing.bg-transparent.navbar-light.show {
        background-color: var(--white) !important;
        border-bottom: 1px solid var(--white);
    }

    .navbar-light .navbar-nav .nav-link {
        color: var(--black-8c);
    }
    .dropdown-menu-end {
        transform: none;
    }

    .p-lg-3 {
        padding-right: calc(var(--bs-gutter-x)* .5) !important;
        padding-left: calc(var(--bs-gutter-x)* .5) !important;
    }

    .d-lg-block {
        display: none !important;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: static;
    }

    .col-lg-7 {
        width: 100%;
    }

    .me-lg-5 {
        margin-right: auto !important;
    }

    .lang_box {
        float: left;
        margin: 10px 0;
        flex-direction: column;
        width: 100%;
    }

    .lang_box>button {
        background: var(--black-8c);
        color: var(--white) !important;
        width: fit-content;
    }

    .lang_box>button:hover {
        background: var(--black-e6);
        color: var(--white);
    }

    .lang_box .dropdown-menu {
        position: static;
        margin-top: 10px;
    }

}

@media only screen and (max-width: 767px) {
    #hero .hero-container {
        min-width: 387px;
    }

    #hero h1 {
        font-size: 22px;
        margin-bottom: 43px;
    }

    #hero p {
        font-size: 39px;
    }

    #hero .countdown div h3 {
        font-size: 27px;
    }

    #hero .countdown div h4 {
        font-size: 14px;
    }

    #hero .countdown div {
        width: 85px;
    }
    
    #hero .countdown {
        margin-top: 21px;
    }

    #clients figure {
        margin-right: 0px;
    }
}

@media only screen and (max-width: 425px) {
    #hero .hero-container {
        min-width: 335px;
    }

    #hero h1 {
        font-size: 19px;
        margin-bottom: 36px;
    }

    #hero p {
        font-size: 33px;
    }

    #hero .countdown div h3 {
        font-size: 23px;
    }

    #hero .countdown div h4 {
        font-size: 12px;
    }

    #hero .countdown div {
        width: 72px;
    }
    
    #hero .countdown {
        margin-top: 18px;
    }
}

@media only screen and (max-width: 375px) {
    #hero .hero-container {
        min-width: 288px;
    }

    #hero h1 {
        font-size: 16px;
        margin-bottom: 31px;
    }

    #hero p {
        font-size: 28px;
    }

    #hero .countdown div h3 {
        font-size: 20px;
    }

    #hero .countdown div h4 {
        font-size: 10px;
    }

    #hero .countdown div {
        width: 61px;
    }
    
    #hero .countdown {
        margin-top: 15px;
    }

    #hero .progress-container {
        width: 200px;
    }
}

@media only screen and (max-width: 320px) {
    #hero .hero-container {
        min-width: 256px;
    }

    #hero h1 {
        font-size: 14px;
        margin-bottom: 26px;
    }

    #hero p {
        font-size: 24px;
    }

    #hero .countdown div h3 {
        font-size: 17px;
    }

    #hero .countdown div h4 {
        font-size: 8px;
    }

    #hero .countdown div {
        width: 52px;
    }
    
    #hero .countdown {
        margin-top: 13px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1182px !important;
    }
    .navbar-marketing {
        padding-top: 0;
        padding-bottom: 0;
    }

    .navbar-marketing.fixed-top {
        max-height: none;
        overflow-y: visible;
    }

    .navbar-marketing .navbar-nav {
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
    }

    .navbar-marketing .navbar-nav .nav-item {
        margin-right: 1rem;
    }

    .dropdown-lg .dropdown-menu, .dropdown-xl .dropdown-menu {
        position: absolute;
        padding: 0;
    }

    .p-lg-5 {
        padding: 2.5rem !important;
    }
    
    .dropdown-xl .dropdown-menu .dropdown-header {
        padding-left: 0;
        padding-right: 0;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        transform: translateX(0);
    }
    .navbar .dropdown-menu.me-lg-n25 {
        top: 10px !important;
        left: 90% !important;
    }

    .me-lg-5 {
        margin-right: auto !important;
    }

    h2 {
        font-size: 1.4rem;
    }
    .post-preview-featured .post-preview-featured-img {
        height: 100%;
        width: 100%;
    }
}

@media (min-width: 992px) {
    .navbar>.container {
        /* background-color: transparent; */
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        margin-top: .75rem;
    }
    100% {
        opacity: 1;
        margin-top: 0;
    }
}

@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}